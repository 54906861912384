
export const environment = {
  production: false,
  build: require('../../package.json').version + ' [debug]',

  appVersion: '2.0.2.2',

  appProt: 'https',
  appPath: 'app.',
  appDomain: 'sboxtst.unpispas.es',
  appPort: null,
  appRoute: '/',

  wwwProt: 'https',
  wwwPath: 'api.',
  wwwDomain: 'sboxtst.unpispas.es',
  wwwPort: null,
  wwwRoute: '/',

  wsrProt: 'https',
  wsrPath: 'wsr.',
  wsrDomain: 'sboxtst.unpispas.es',
  wsrPort: null,
  wsrRoute: '/',
  
  googleAnalytics: 'True',

  onlinepay_sandbox: true,
  ticketsii_sandbox: true,
  ticketbai_sandbox: true
};

import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { TicketChange } from "./ticketchange";

import { GenericUtils } from '@app/app.commonutils';
import { AppConstants } from "@app/app.constants";
import { AccountInvoice } from './accountinvoice';

/*
    How does TicketBai integration works
    There is a database table: TICKETBAI that is updated in the server
    - Relation with the ticket
    - Contents of the ticketbai-id
    - Contents of the ticketbai-qr
    - Link to the xml file with the ticektbai information
    - Errorcode returned by the ticketbai server
    - CSV code returned by the ticketbai server

    The ticket has a ticketbai() property that returns an instance of this class
    The XML is only generated if it hasn't been sent to ticketbai
    - This is done in the set ticket() operator -- only called when is newly created
    Otherwise, we read the ticket-id, and ticket-qr from the database
    The XML is sent in the ticketbai commit operation
    - It is only sent if it has been generated, otherwise no chages are required in teh server
    - Is evaluated in the server, compressed and stored in a disk file (no tin ddbb)
    - The ticketbai XML files are stored in the regstr folder on the server
    - Then it is sent to the ticketbai server and keeps the CSV returned code

    Offline considerations:
        The data sent to the server is stored in the syncchanges variable
        This data will contain the full XML file, so it will be sent as soon as connection is back
*/

/*******************************/
/* TICKETBAI DATA CLASS        */
/*******************************/

export interface _TicketBAI {
    change: number,
    invceac: number,
    id: string,
    qr: string,
    xmldata?: string,
    xmlfile?: string,
    sign: string,
    error: string,
    valid: string
};

interface _TicketBAIData extends _TicketBAI {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketBAIData extends DataObject {
    protected _ticketbai: _TicketBAIData = {
        change: null,
        invceac: null,
        id: null,
        qr: null,
        xmldata: null,
        xmlfile: null,
        sign: null,
        error: null,
        valid: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);

        this._ticketbai.created = new Date();
        this._ticketbai.updated = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._ticketbai.created;
    }

    get updated(){
        return this._ticketbai.updated;        
    }

    get change(): TicketChange {
        return this._children['change'] || null;
    }

    set change(value: TicketChange){
        if (this.SetChild('change', value, 'change')){
            this.ToUpdate = true;
        }
    }

    get invceac(): AccountInvoice {
        return this._children['invceac'] || null;
    }

    set invceac(value: AccountInvoice){
        if (this.SetChild('invceac', value, 'invceac')){
            this.ToUpdate = true;
        }
    }

    /* read only properties */

    get id(){
        return this._ticketbai.id;
    }

    get qr(){
        return this._ticketbai.qr;
    }

    get xmldata(){
        return this._ticketbai.xmldata;
    }

    get xmlfile(){
        return this._ticketbai.xmlfile;
    }

    get sign(){
        return this._ticketbai.sign;
    }

    get error(){
        return this._ticketbai.error;
    }

    get valid(){
        return this._ticketbai.valid;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            tckchg: this._ticketbai.change,
            invceac: this._ticketbai.invceac,
            id: this._ticketbai.id,         
            qr: this._ticketbai.qr,         
            xml: this._ticketbai.xmldata,    // this is the xml content sent to the server
            sign: this._ticketbai.sign
        };
    }

    protected get Depend() {
        return {
            tckchg: { item: this.change, relation_info: { to: 'ticketbai', by: 'change' } },     // this[by -> 'change'][to -> 'ticketbai'] => this
            invceac: { item: this.invceac, relation_info: { to: 'ticketbai', by: 'invceac' } },  // this[by -> 'invceac'][to -> 'ticketbai'] => this
        };
    }

    protected get Children(){
        return [];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_ticketbai: _TicketBAI){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._ticketbai, 'change', _ticketbai['change']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'invceac', _ticketbai['invceac']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'id', _ticketbai['id']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'qr', _ticketbai['qr']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'xmlfile', _ticketbai['xmlfile']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'sign', _ticketbai['sign']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'error', _ticketbai['error']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticketbai, 'valid', _ticketbai['valid']) || _toUpdate;
    
        return _toUpdate;
    }   

    set Data(_ticketbai: _TicketBAI){
        this.patchValue(this._ticketbai, 'created', _ticketbai['created']);
        this.patchValue(this._ticketbai, 'updated', _ticketbai['updated']);
        
        if (this._patchData(_ticketbai)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._ticketbai;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_ticketbai: _TicketBAI){
        this._patchData(_ticketbai);

        if (_ticketbai['change']){    // update children: 'change'
            let _objid = _ticketbai['change'].toString();
            this.SetChild('change', new TicketChange(_objid, this.data, this._objoptions), 'change')
        }
        else {
            this.SetChild('change', null, 'change');
        }
    }

    private _ddbb(info): _TicketBAIData {
        let _ticketbai: _TicketBAIData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            change: info['tckchg'] ? parseInt(info['tckchg']) : null,
            invceac: info['invceac'] ? parseInt(info['invceac']) : null,
            id: info['id'],
            qr: info['qr'],
            xmlfile: info['xml'],   // this is the xmlfile returned from the server
            sign: info['sign'],
            error: info['error'],
            valid: info['valid'],
        };
        return _ticketbai;
    }

    protected _OnUpdate(info){
        let _ticketbai = this._ddbb(info);
        this.patchValue(this._ticketbai, 'objid', _ticketbai['objid']);
        this.patchValue(this._ticketbai, 'created', _ticketbai['created']);
        this.patchValue(this._ticketbai, 'updated', _ticketbai['updated']);
        this.DoPatchValues(_ticketbai);
    }
}

export class TicketBAI extends TicketBAIData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETBAI', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketBAI {
        return this._Copy(store) as TicketBAI;
    }

    /****************************/
    /* CUSTOM MEMBERS           */
    /****************************/

    private _ticketbaicalc = null;
    private get TbaiCalc(){
        if (this._ticketbaicalc == null){
            this._ticketbaicalc = new TicketBaiCalc(this.change, this.invceac, this.data);
        }
        return this._ticketbaicalc;
    }

    get IsAvailable(){
        return (this.TbaiCalc.IsAvailable);
    }

    async Refresh(){
        if (this.change){
            // do not calculate if no invceno
            if (!this.change.invoice){
                return {
                    'info': null,
                    'code': null
                }              
            }

            if (this.change.ToInsert || this.change.IsLoaded){
                if (this.change.reason == 'C'){     // this is a cancellation
                    // recalculate the xml
                    this._ticketbai.xmldata = await this.TbaiCalc.xml();
                    this._ticketbai.sign = await this.TbaiCalc.sign();
                    
                    if ((this._ticketbai.xmldata == null) || (this._ticketbai.sign == null)){
                        return null;    // error obtaining ticketbai information
                    }

                    // cancelled tickets do not provide qr
                    this._ticketbai.id = null;
                    this._ticketbai.qr = null;
                }
                else {
                    this._toUpdate  = (!this._ticketbai.id || !this._ticketbai.qr || this.change.ToUpdate);

                    if(this._toUpdate ) { 
                        console.info("[TICKETBAI] Generating XML data (change reason '" + this.change.reason+ "')");

                        // recalculate the xml
                        this._ticketbai.xmldata = await this.TbaiCalc.xml();
                        this._ticketbai.sign = await this.TbaiCalc.sign();

                        if ((this._ticketbai.xmldata == null) || (this._ticketbai.sign == null)){
                            return null;    // error obtaining ticketbai information
                        }
                            
                        // recalculate id and qr
                        this._ticketbai.id = await this.TbaiCalc.id();
                        this._ticketbai.qr = await this.TbaiCalc.qr();        
                    }                    
                }

                return {
                    'info': this._ticketbai.id,
                    'code': this._ticketbai.qr
                }  
            }
        }

        if (this.invceac){
            // do not calculate if no invceno
            if (!this.invceac.invoice){
                return {
                    'info': null,
                    'code': null
                }              
            }

            this._toUpdate  = (!this._ticketbai.id || !this._ticketbai.qr);

            if(this._toUpdate ) { 
                console.info("[TICKETBAI] Generating XML data (change reason 'A')");

                // recalculate the xml
                this._ticketbai.xmldata = await this.TbaiCalc.xml();
                this._ticketbai.sign = await this.TbaiCalc.sign();

                if ((this._ticketbai.xmldata == null) || (this._ticketbai.sign == null)){
                    return null;    // error obtaining ticketbai information
                }
                    
                // recalculate id and qr
                this._ticketbai.id = await this.TbaiCalc.id();
                this._ticketbai.qr = await this.TbaiCalc.qr();                       
            }

            return {
                'info': this._ticketbai.id,
                'code': this._ticketbai.qr
            }  
        }

        return null;    // ticket is not ready (not created and not loaded)
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.valid != null);
    }
}

/*******************************/
/* TICKETBAI LOGIC CLASS       */
/*******************************/

class TicketBaiCalc {
    private _ticketbai_id = null;
    private _ticketbai_qr = null;

    get place(){
        if (this._change){
            return this._change.ticket.place;
        }

        if (this._invceac){
            return this._invceac.account.place;
        }

        return null;
    }

    get client(){
        if (this._change){
            return this._change.client;
        }

        if (this._invceac){
            return this._invceac.account.client;
        }

        return null;
    }

    get IsAvailable(){
        return (this.PlaceProvince != null);
    }

    /*
        id: Código identificativo
        - 4 caracteres de texto fijo en mayúscula: TBAI.
        - 1 carácter “-“ como separador. Guion medio.
        - 9 caracteres del NIF de la persona o entidad emisora de la factura o justificante.
        - 1 carácter “-“ como separador. Guion medio.
        - 6 caracteres de la fecha de expedición de la factura o justificante (DDMMAA).
        - 1 carácter “-“ como separador. Guion medio.
        - 13 primeros caracteres de la firma del fichero de alta TicketBAI, (13 primeros caracteres delcampo SignatureValue del fichero de alta TicketBAI asociado a la factura o justificante)
        - 1 carácter “-“ como separador. Guion medio.
        - 3 caracteres que se corresponden con un código de detección de errores
    */    

    async id(){
        return new Promise(async (resolve) => {
            if (this._ticketbai_id == null){
                
                let _id = "";
    
                _id += "TBAI-";
                _id += this.place.taxid + "-";

                if (this._change){
                    _id += this._ticketbai_date(this._change.created) + "-";
                }

                if (this._invceac){
                    _id += this._ticketbai_date(this._invceac.created) + "-";
                }
                
                _id += await this._ticketbai_sign(13) + "-";
                _id += this._ticketbai_crc8(_id)
        
                this._ticketbai_id = _id
            }

            resolve(this._ticketbai_id);
        });
    }

    /*
        id: Código identificativo
        - Url del servicio ticektbai de consulta de códigos QR.
        - ?id: Identificador TBAI codificado dentro de la URL.
        - &s: Número de serie del ticket
        - &nf: Número de la factura dentro de la serie
        - &i: Importe de la factura
        - &cr: Código de validación CRC8
    */    

    async qr(){
        return new Promise(async (resolve) => {
            if (this._ticketbai_qr == null){
                
                let _qr = "";
    
                _qr = this._ticketbai_url() + '/';
                _qr += '?id=' + encodeURIComponent(await this.id() as string);
                _qr += '&s=' + await this._ticketbai_s();
                _qr += '&nf=' + await this._ticketbai_nf();
                _qr += '&i=' + await this._ticketbai_i();
                _qr += '&cr=' + this._ticketbai_crc8(_qr); 

                this._ticketbai_qr = _qr
            }

            resolve(this._ticketbai_qr);
        });
    }

    async xml(){
        return await this._ticketbai_xml();
    }

    async sign(){
        return await this._ticketbai_sign(100);
    }

    constructor(private _change: TicketChange, private _invceac: AccountInvoice, private _data: dataService){
        // nothing to do
    }

    /* resolve ticketbai URL */

    private get _Province(){
        let _address = this.place.fiscal.formatted;
        
        let _bizkaia = [ /Bizkaia/i, /Vizcaya/i ];
        for(let _pattern of _bizkaia){
            if (_address.search(_pattern) != -1){
                return 'Bizkaia';
            }
        }

        let _gipuzkoa = [ /Guipúzcoa/i, /Guipuzcoa/i, /Gipuzkoa/i ] 
        for(let _pattern of _gipuzkoa){
            if (_address.search(_pattern) != -1){
                return 'Gipuzkoa';
            }
        }

        let _araba = [ /Álava/i, /Alava/i, /Araba/i ]
        for(let _pattern of _araba){
            if (_address.search(_pattern) != -1){
                return 'Araba';
            }
        }

        return null;    // not a ticketbai province
    }

    private _place_province = null;
    private get PlaceProvince(){
        if (!this.place.fiscal){
            return null;    // no fiscal address provided
        }

        if (!this._place_province){
            this._place_province = this._Province;
        }
        return this._place_province;
    }

    private _TicketBai_Url = {
        Bizkaia: {
            policy: {
                identifier: {
                    value: "https://www.batuz.eus/fitxategiak/batuz/ticketbai/sinadura_elektronikoaren_zehaztapenak_especificaciones_de_la_firma_electronica_v1_0.pdf",
                },
                qualifiers: [
                    "https://www.batuz.eus/fitxategiak/batuz/ticketbai/sinadura_elektronikoaren_zehaztapenak_especificaciones_de_la_firma_electronica_v1_0.pdf"
                ],
                digestValue: 'Quzn98x3PMbSHwbUzaj5f5KOpiH0u8bvmwbbbNkO9Es='
            },
            sbox: 'https://batuz.eus/QRTBAI/',
            prod:'https://batuz.eus/QRTBAI/'
        },
        Gipuzkoa: {
            policy: {
                identifier: {
                    value: "https://www.gipuzkoa.eus/ticketbai/sinadura"
                },
                digestValue: "vSe1CH7eAFVkGN0X2Y7Nl9XGUoBnziDA5BGUSsyt8mg="
            },
            sbox:'https://tbai.prep.gipuzkoa.eus/qr/',
            prod:'https://tbai.egoitza.gipuzkoa.eus/qr/'
        },
        Araba: {
            policy: {
                identifier: {
                    value: "https://ticketbai.araba.eus/tbai/sinadura/"
                },
                digestValie: "4Vk3uExj7tGn9DyUCPDsV9HRmK6KZfYdRiW3StOjcQA="
            },
            sbox: 'https://pruebas-ticketbai.araba.eus/tbai/qrtbai/',
            prod: 'https://ticketbai.araba.eus/tbai/qrtbai'
        }
    } 

    /* calculate ticketbai fields */

    private readonly crc8_table = new Uint8Array([
        0x00, 0x07, 0x0E, 0x09, 0x1C, 0x1B, 0x12, 0x15,
        0x38, 0x3F, 0x36, 0x31, 0x24, 0x23, 0x2A, 0x2D,
        0x70, 0x77, 0x7E, 0x79, 0x6C, 0x6B, 0x62, 0x65,
        0x48, 0x4F, 0x46, 0x41, 0x54, 0x53, 0x5A, 0x5D,
        0xE0, 0xE7, 0xEE, 0xE9, 0xFC, 0xFB, 0xF2, 0xF5,
        0xD8, 0xDF, 0xD6, 0xD1, 0xC4, 0xC3, 0xCA, 0xCD,
        0x90, 0x97, 0x9E, 0x99, 0x8C, 0x8B, 0x82, 0x85,
        0xA8, 0xAF, 0xA6, 0xA1, 0xB4, 0xB3, 0xBA, 0xBD, 
        0xC7, 0xC0, 0xC9, 0xCE, 0xDB, 0xDC, 0xD5, 0xD2,
        0xFF, 0xF8, 0xF1, 0xF6, 0xE3, 0xE4, 0xED, 0xEA,
        0xB7, 0xB0, 0xB9, 0xBE, 0xAB, 0xAC, 0xA5, 0xA2,
        0x8F, 0x88, 0x81, 0x86, 0x93, 0x94, 0x9D, 0x9A,
        0x27, 0x20, 0x29, 0x2E, 0x3B, 0x3C, 0x35, 0x32,
        0x1F, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0D, 0x0A,
        0x57, 0x50, 0x59, 0x5E, 0x4B, 0x4C, 0x45, 0x42,
        0x6F, 0x68, 0x61, 0x66, 0x73, 0x74, 0x7D, 0x7A,
        0x89, 0x8E, 0x87, 0x80, 0x95, 0x92, 0x9B, 0x9C,
        0xB1, 0xB6, 0xBF, 0xB8, 0xAD, 0xAA, 0xA3, 0xA4,
        0xF9, 0xFE, 0xF7, 0xF0, 0xE5, 0xE2, 0xEB, 0xEC,
        0xC1, 0xC6, 0xCF, 0xC8, 0xDD, 0xDA, 0xD3, 0xD4,
        0x69, 0x6E, 0x67, 0x60, 0x75, 0x72, 0x7B, 0x7C,
        0x51, 0x56, 0x5F, 0x58, 0x4D, 0x4A, 0x43, 0x44,
        0x19, 0x1E, 0x17, 0x10, 0x05, 0x02, 0x0B, 0x0C,
        0x21, 0x26, 0x2F, 0x28, 0x3D, 0x3A, 0x33, 0x34,
        0x4E, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5C, 0x5B,
        0x76, 0x71, 0x78, 0x7F, 0x6A, 0x6D, 0x64, 0x63,
        0x3E, 0x39, 0x30, 0x37, 0x22, 0x25, 0x2C, 0x2B,
        0x06, 0x01, 0x08, 0x0F, 0x1A, 0x1D, 0x14, 0x13,
        0xAE, 0xA9, 0xA0, 0xA7, 0xB2, 0xB5, 0xBC, 0xBB,
        0x96, 0x91, 0x98, 0x9F, 0x8A, 0x8D, 0x84, 0x83,
        0xDE, 0xD9, 0xD0, 0xD7, 0xC2, 0xC5, 0xCC, 0xCB,
        0xE6, 0xE1, 0xE8, 0xEF, 0xFA, 0xFD, 0xF4, 0xF3
    ]);

    private _ticketbai_date(date: Date){
        if (date){
            let _dd = ("0" + date.getDate()).slice(-2);
            let _mm = ("0" + (date.getMonth()+1)).slice(-2);
            let _yy = date.getFullYear().toString().slice(-2);

            return _dd + _mm + _yy;
        }
        else {
            return "000000";
        }
    }  
    
    private _ticketbai_data = null;
    private async _ticketbai_xml(){
        if (!this._ticketbai_data){
            this._ticketbai_data = await this._buildXML();
        }
        return this._ticketbai_data;
    }

    private async _ticketbai_sign(length: 13 | 100){
        let _ticketbai_xml = await this._ticketbai_xml();
        if (_ticketbai_xml){
            let _xml = (new DOMParser()).parseFromString(_ticketbai_xml, "application/xml");
            let _signatureValues = _xml.getElementsByTagNameNS("http://www.w3.org/2000/09/xmldsig#", "SignatureValue");
            if (_signatureValues.length == 1){
                return (_signatureValues[0].textContent).slice(0, length);
            }
        }
        return null;
    }

    private _ticketbai_crc8(input){
        const data = new TextEncoder().encode(input);
        const len = data.length;
        let crc = 0;
        for (let i = 0; i < len; i++) {
          crc = this.crc8_table[(crc ^ data[i]) & 0xFF];
        }
        return ("000" + (crc & 0xFF).toString()).slice(-3);
    }    
    
    private _ticketbai_url(){
        let _urls = this._TicketBai_Url[this.PlaceProvince];
        if (_urls){
            return AppConstants.TicketBaiSandbox ? _urls.sbox : _urls.prod;
        }
        return null;
    }

    private _ticketbai_s(){
        if (this._change){
            return this._change.series;            
        }

        if (this._invceac){
            return this._invceac.series;
        }

        return null;
    }

    private _ticketbai_nf(){
        if (this._change){
            return this._change.invoice;            
        }

        if (this._invceac){
            return this._invceac.invoice;
        }

        return null;
    }

    private _ticketbai_i(){
        if (this._change){
            return this._change.total.toFixed(2);
        }

        if (this._invceac){
            return this._invceac.total.toFixed(2);
        }

        return null;
    }

    /* obtain signing cerificate */

    private _usercert = null;
    private async ReadUserCert(){
        let _itemid = this.place.objid + "_secured_cr";
        let _tokeep = false;

        // try to read the certificate from the session storage
        if (!this._usercert){
            let _storeddata = await this._data.GetSessionSecured(_itemid)
            if (_storeddata){
                this._usercert = GenericUtils.base64ToArrayBuffer(_storeddata);
            }
        }

        // try to read the certificate from the remote server
        if (!this._usercert){
            _tokeep = true;     // will be stored in the session

            let _certurl = this.place.PemCert.url;
            if (!this._usercert && _certurl){
                this._usercert = await this._data.FetchFile(_certurl);
                if (!this._usercert){
                    console.error("Could not read certificate @ '" + _certurl + "'") 
                }
            }

            let _certb64 = this.place.PemCert.base64;
            if (!this._usercert && _certb64){
                let _base64 = _certb64;
                if (_base64.startsWith('data:')){
                    _base64 = _base64.split(',')[1];
                }

                let _binary = atob(_base64);
                let _length = _binary.length;
                let _bytesa = new Uint8Array(_length);
                for (let i = 0; i < _length; i++) {
                    _bytesa[i] = _binary.charCodeAt(i);
                }

                this._usercert = _bytesa.buffer;
            }
        }

        if (this._usercert && _tokeep){
            let _storeddata = GenericUtils.arrayBufferToBase64(this._usercert);
            if (_storeddata){
                await this._data.SetSessionSecured(_itemid, _storeddata);
            }
        }

        return this._usercert;
    }

    private _passcert = null;
    private async ReadPassCert(){
        let _itemid = this.place.objid + "_secured_cp";
        let _tokeep = false;

        // try to read the password from the session storage
        if (!this._passcert){
            this._passcert = await this._data.GetSessionSecured(_itemid)
        }

        // try to read the password from the remote server
        if (!this._passcert){
            _tokeep = true;     // will be stored in the session

            let _passcert = await this._data.GetServerSecured('PLACE', this.place.objid, 'certkey');
            if (_passcert !== false){
                this._passcert = _passcert || '';
            }
        }

        if (this._passcert && _tokeep){
            await this._data.SetSessionSecured(_itemid, this._passcert)
        }

        return this._passcert;
    }

    /* generate ticketbai XML */

    private _ticketbai_xml_date(date){
        let _dd = ("0" + date.getDate()).slice(-2);
        let _mm = ("0" + (date.getMonth()+1)).slice(-2);
        let _yy = date.getFullYear();

        return _dd + '-' + _mm + '-' + _yy;
    }

    private _ticketbai_xml_time(date){
        let _hh = ("0" + date.getHours()).slice(-2);
        let _mm = ("0" + date.getMinutes()).slice(-2);
        let _ss = ("0" + date.getSeconds()).slice(-2);

        return _hh + ':' + _mm + ':' + _ss;
    }

    private _XMLSujetos(){
        let _data = {
            Emisor: {
                NIF: this.place.taxid,
                ApellidosNombreRazonSocial: this.place.business
            },
            Destinatario: null
        };

        let _client = this.client;
        if (_client){
            _data.Destinatario = {
                NIF: _client.taxid,
                ApellidosNombreRazonSocial: _client.name,
                CodigoPostal: _client.postalcode || '000000',
                Direccion: _client.address
            }
        }

        return _data;
    }

    private _WoutTax(rate, amount){
        return (Number(amount) / ((100 + rate)/100));
    }

    private _XMLChangeFactura(){
        let _data = {
            SerieFactura: this._ticketbai_s(),
            NumFactura: this._ticketbai_nf(),
            FechaExpedicionFactura: this._ticketbai_xml_date(this._change.created),
            HoraExpedicionFactura: this._ticketbai_xml_time(this._change.created),
            FacturaSimplificada: (this.client) ? 'N': 'S',
            FacturaEmitidaSustitucionSimplificada: (this._change.reason == 'I') ? 'S': 'N',
            DescripcionFactura: "Ticket " + this.place.name,
            DetallesFactura: [],
            DetalleIVA: [],
            FacturaRectificativa: null,
            ImporteTotalFactura: (this._change.total).toFixed(2),
        };

        let _taxrates = this._change.ticket.SplitTaxes;
        for (let _taxrate of _taxrates){
            _data.DetalleIVA.push({
                BaseImponible: _taxrate.base,
                TipoImpositivo: _taxrate.rate,
                CuotaImpuesto: _taxrate.taxes
            })
        }

        /* if changes in invoice destination or invoice price, then it is a rectification */
        if (['P', 'I', 'D'].indexOf(this._change.reason) != -1){
            let _prev = this._change.prev;
            if (_prev){
                switch(this._change.reason){
                    case 'P':
                        _data['DescripcionFactura'] = 'Cambios en importe';
                        break;
                    case 'D':
                        _data['DescripcionFactura'] = 'Cambios en destinatario';
                        break;
                    case 'I':
                        _data['DescripcionFactura'] = 'Emisión de factura';
                        break;
                }

                _data['FacturaRectificativa'] = {
                    Codigo: (this.client) ? 'R4': 'R5',
                    Tipo: 'S',                          // Sustitucion
                    BaseRectificada: (this._change.base - _prev.base).toFixed(2),
                    CuotaRectificada: (this._change.taxes - _prev.taxes).toFixed(2),
                    IDFacturaRectificadaSustituida: [{
                        SerieRectificada: _prev.series,
                        NumeroRectificada: _prev.invoice,
                        FechaRectificada: this._ticketbai_xml_date(_prev.created)        
                    }]
                }    
            }
        }

        let _products = [];
        _products = _products.concat(this._change.ticket.ProductsToPrepare);
        _products = _products.concat(this._change.ticket.ProductsDelivered);

        for(let _item of _products){
            if (_item.product.product == null){
                continue;   // do not notify ticket separators
            }

            let _taxrate = _item.product.requested[0].taxrate;
            let _oneprice = _item.product.requested[0].TotalPrice;

            let _applyprc = 0;
            let _totalprc = 0;
            let _discount = 0;

            for(let _requested of _item.product.requested){
                _applyprc += _requested.ChargePrice;
                _totalprc += _requested.TotalPrice;
            }

            _discount = _totalprc - _applyprc;
            
            _totalprc = Math.round(_totalprc * 100) / 100;
            _discount = Math.round(_discount * 100) / 100;

            let _detalle = {
                DescripcionDetalle: _item.product.product.name,
                Cantidad: _item.product.requested.length.toString(),
                ImporteUnitario: this._WoutTax(_taxrate, _oneprice).toFixed(2),  // importe sin IVA
                Descuento: this._WoutTax(_taxrate, _discount).toFixed(2),        // importe sin IVA
                ImporteTotal: _applyprc.toFixed(2)
            }

            _data.DetallesFactura.push(_detalle);
        }

        let _extras = [];
        _extras = _extras.concat(this._change.ticket.ExtrasToPrepare);

        for(let _item of _extras){
            let _taxrate = _item.taxtate;
            let _detalle = {
                DescripcionDetalle: _item.extra.name,
                Cantidad: '1',
                ImporteUnitario: this._WoutTax(_taxrate, _item.ChargePrice).toFixed(2),  // importe sin IVA
                Descuento: '0.00',
                ImporteTotal: _item.ChargePrice.toFixed(2)
            }

            _data.DetallesFactura.push(_detalle);
        }

        let _discounts = [];
        _discounts = _discounts.concat(this._change.ticket.DiscountsToApply);

        for(let _item of _discounts){
            if (_item.charge == 0){
                continue;   // the discount has not been applied (ticket total below 0)
            }

            let _splitdiscounts = _item.SplitTaxes;
            for(let _discount of _splitdiscounts){
                let _taxrate = _discount.rate;
                let _detalle = {
                    DescripcionDetalle: "Descuento: " + _item.discount.name,
                    Cantidad: '1',
                    ImporteUnitario: 0.00,
                    Descuento: this._WoutTax(_taxrate, _discount.total).toFixed(2),        // importe sin IVA,
                    ImporteTotal: (0 - _discount.total).toFixed(2)
                }

                _data.DetallesFactura.push(_detalle);
            }
        }

        return _data;
    }

    private _XMLInvceacFactura(){
        let _data = {
            SerieFactura: this._ticketbai_s(),
            NumFactura: this._ticketbai_nf(),
            FechaExpedicionFactura: this._ticketbai_xml_date(this._invceac.created),
            HoraExpedicionFactura: this._ticketbai_xml_time(this._invceac.created),
            FacturaSimplificada: (this._invceac.account.client) ? 'N': 'S',
            FacturaEmitidaSustitucionSimplificada: 'N',
            DescripcionFactura: "Factura Recapitulativa",
            DetallesFactura: [],
            DetalleIVA: [],
            FacturaRectificativa: null,
            ImporteTotalFactura: (this._invceac.total).toFixed(2)
        };

        let _taxrates = this._invceac.SplitTaxes;
        for (let _taxrate of _taxrates){
            _data.DetalleIVA.push({
                BaseImponible: _taxrate.base,
                TipoImpositivo: _taxrate.rate,
                CuotaImpuesto: _taxrate.taxes
            })
        }

        /* add the rectified invoices information */
        let _FacturasSustituidas = [];
        for (let _included of this._invceac.tickets){
            _FacturasSustituidas.push({
                SerieRectificada: _included.series,
                NumeroRectificada: _included.invceno,
                FechaRectificada: this._ticketbai_xml_date(_included.created) 
            });
        }

        _data['FacturaRectificativa'] = {
            Codigo: (this.client) ? 'R4': 'R5',
            Tipo: 'S',                          // Sustitucion
            BaseRectificada: '0.00',
            CuotaRectificada: '0.00',
            IDFacturaRectificadaSustituida: _FacturasSustituidas
        }

        /* add the rectified invoices as the detail */
        for (let _included of this._invceac.tickets){
            let _basePrice = 0;

            let _taxrates = _included.SplitTaxes;
            for (let _taxrate of _taxrates){
                _basePrice += _taxrate.base;
            }

            let _detalle = {
                DescripcionDetalle: _included.series + _included.invceno,
                Cantidad: '1',
                ImporteUnitario: _basePrice.toFixed(2),  // importe sin IVA
                Descuento: '0.00',
                ImporteTotal: _included.price.toFixed(2)
            }

            _data.DetallesFactura.push(_detalle);            
        }

        return _data;
    }

    private async _XMLHuella(){
        let _region = this.PlaceProvince;

        let _data = {
            anterior: null,     // will be calculated afterwards
            LicenciaTBAI: AppConstants.TicketBaiLicense(_region),
            EntidadDesarrolladora: {
                NIF: AppConstants.TicketBaiNIF(_region)
            },
            NombreAplicacion: AppConstants.TicketBaiAppname(_region),
            VersionAplicacion: AppConstants.TicketBaiAppvers(_region),
            NumSerieDispositivo: this._data.CRC32(this._data.device).toString(16)
        };

        let _previous = await this._data.GetLastInvoice();
        if (_previous){
            _data.anterior = {
                FechaExpedicionFacturaAnterior: _previous['created'],
                SerieFacturaAnterior: _previous['series'],
                NumFacturaAnterior: _previous['invoice'],
                SignatureValueFirmaFacturaAnterior: _previous['tbai']
            }
        }

        return _data;
    }

    private _xmldata = null;
    private async XMLData(){
        if (!this._xmldata){
            this._xmldata = {
                Sujetos: this._XMLSujetos(),
                Factura: null,  // Calculated below
                HuellaTBAI: await this._XMLHuella()
            };

            if (this._invceac != null){
                this._xmldata.Factura = this._XMLInvceacFactura();
            }

            if (this._change != null){
                this._xmldata.Factura = this._XMLChangeFactura();
            }
        }
        return this._xmldata
    }

    private _UnsignedXML_Baja(_XMLData){
        let _xml = '<?xml version="1.0" encoding="UTF-8"?>';

        _xml += `
            <T:AnulaTicketBai xmlns:T="urn:ticketbai:anulacion">
            <Cabecera>
                <IDVersionTBAI>1.2</IDVersionTBAI>
            </Cabecera>
        `;

        /* factura */

        _xml += `
            <IDFactura>
                <Emisor>
                    <NIF>${_XMLData['Sujetos']['Emisor']['NIF']}</NIF>
                    <ApellidosNombreRazonSocial>${_XMLData['Sujetos']['Emisor']['ApellidosNombreRazonSocial']}</ApellidosNombreRazonSocial>
                </Emisor>
                <CabeceraFactura>
                    <SerieFactura>${_XMLData['Factura']['SerieFactura']}</SerieFactura>
                    <NumFactura>${_XMLData['Factura']['NumFactura']}</NumFactura>
                    <FechaExpedicionFactura>${_XMLData['Factura']['FechaExpedicionFactura']}</FechaExpedicionFactura>
                </CabeceraFactura>
            </IDFactura>
        `;

        /* huella */

        _xml += `
            <HuellaTBAI>
        `;

        _xml += `
            <Software>
                <LicenciaTBAI>${_XMLData['HuellaTBAI']['LicenciaTBAI']}</LicenciaTBAI>
                <EntidadDesarrolladora>
                    <NIF>${_XMLData['HuellaTBAI']['EntidadDesarrolladora']['NIF']}</NIF>
                </EntidadDesarrolladora>
                <Nombre>${_XMLData['HuellaTBAI']['NombreAplicacion']}</Nombre>
                <Version>${_XMLData['HuellaTBAI']['VersionAplicacion']}</Version>
            </Software>
            <NumSerieDispositivo>${_XMLData['HuellaTBAI']['NumSerieDispositivo']}</NumSerieDispositivo>
        `;
        
        _xml += `
            </HuellaTBAI>
        `;

        _xml += `
            </T:AnulaTicketBai>   
        `;

        return _xml;
    }

    private _UnsignedXML_Alta(_XMLData){
        let _xml = '<?xml version="1.0" encoding="UTF-8"?>';

        _xml += `
            <T:TicketBai xmlns:T="urn:ticketbai:emision">
            <Cabecera>
                <IDVersionTBAI>1.2</IDVersionTBAI>
            </Cabecera>
        `;

        /* sujetos */

        _xml += `
	        <Sujetos>
                <Emisor>
                    <NIF>${_XMLData['Sujetos']['Emisor']['NIF']}</NIF>
                    <ApellidosNombreRazonSocial>${_XMLData['Sujetos']['Emisor']['ApellidosNombreRazonSocial']}</ApellidosNombreRazonSocial>
                </Emisor>
        `;

        /* en el caso de ser una factura a cliente */

        if (_XMLData['Sujetos']['Destinatario'] != null){
            _xml += `
                <Destinatarios>
                    <IDDestinatario>
                        <NIF>${_XMLData['Sujetos']['Destinatario']['NIF']}</NIF>
                        <ApellidosNombreRazonSocial>${_XMLData['Sujetos']['Destinatario']['ApellidosNombreRazonSocial']}</ApellidosNombreRazonSocial>
                        <CodigoPostal>${_XMLData['Sujetos']['Destinatario']['CodigoPostal']}</CodigoPostal>
                        <Direccion>${_XMLData['Sujetos']['Destinatario']['Direccion']}</Direccion>
                    </IDDestinatario>
                </Destinatarios>
                <VariosDestinatarios>N</VariosDestinatarios>
                <EmitidaPorTercerosODestinatario>N</EmitidaPorTercerosODestinatario>
            `;
        }

        _xml += `
	        </Sujetos>        
        `;

        /* factura */

        _xml += `
            <Factura>
                <CabeceraFactura>
                    <SerieFactura>${_XMLData['Factura']['SerieFactura']}</SerieFactura>
                    <NumFactura>${_XMLData['Factura']['NumFactura']}</NumFactura>
                    <FechaExpedicionFactura>${_XMLData['Factura']['FechaExpedicionFactura']}</FechaExpedicionFactura>
                    <HoraExpedicionFactura>${_XMLData['Factura']['HoraExpedicionFactura']}</HoraExpedicionFactura>
                    <FacturaSimplificada>${_XMLData['Factura']['FacturaSimplificada']}</FacturaSimplificada>
                    <FacturaEmitidaSustitucionSimplificada>${_XMLData['Factura']['FacturaEmitidaSustitucionSimplificada']}</FacturaEmitidaSustitucionSimplificada>
        `;

        if (_XMLData['Factura']['FacturaRectificativa']){
            _xml += `
                <FacturaRectificativa>
                    <Codigo>${_XMLData['Factura']['FacturaRectificativa']['Codigo']}</Codigo>
                    <Tipo>${_XMLData['Factura']['FacturaRectificativa']['Tipo']}</Tipo>
                    <ImporteRectificacionSustitutiva>
                        <BaseRectificada>${_XMLData['Factura']['FacturaRectificativa']['BaseRectificada']}</BaseRectificada>
                        <CuotaRectificada>${_XMLData['Factura']['FacturaRectificativa']['CuotaRectificada']}</CuotaRectificada>
                    </ImporteRectificacionSustitutiva>
                </FacturaRectificativa>        
                <FacturasRectificadasSustituidas>
            `;

            for (let _FacturaRectificada of _XMLData['Factura']['FacturaRectificativa']['IDFacturaRectificadaSustituida']){
                _xml += `
				<IDFacturaRectificadaSustituida>
					<SerieFactura>${_FacturaRectificada['SerieRectificada']}</SerieFactura>
					<NumFactura>${_FacturaRectificada['NumeroRectificada']}</NumFactura>
					<FechaExpedicionFactura>${_FacturaRectificada['FechaRectificada']}</FechaExpedicionFactura>
				</IDFacturaRectificadaSustituida>
                `;
            }
                
			_xml += `
                </FacturasRectificadasSustituidas>                    
            `;
        }

        _xml += `
                </CabeceraFactura>
                <DatosFactura>
                    <DescripcionFactura>${_XMLData['Factura']['DescripcionFactura']}</DescripcionFactura>
                    <DetallesFactura>
        `;

        for (let _detalle of _XMLData['Factura']['DetallesFactura']){
            _xml += `
                        <IDDetalleFactura>
                            <DescripcionDetalle>${_detalle['DescripcionDetalle']}</DescripcionDetalle>
                            <Cantidad>${_detalle['Cantidad']}</Cantidad>
                            <ImporteUnitario>${_detalle['ImporteUnitario']}</ImporteUnitario>
                            <Descuento>${_detalle['Descuento']}</Descuento>
                            <ImporteTotal>${_detalle['ImporteTotal']}</ImporteTotal>
                        </IDDetalleFactura>
            `;
        }

        _xml += `
                    </DetallesFactura>
                    <ImporteTotalFactura>${_XMLData['Factura']['ImporteTotalFactura']}</ImporteTotalFactura>
                    <Claves>
                        <IDClave>
                            <ClaveRegimenIvaOpTrascendencia>01</ClaveRegimenIvaOpTrascendencia>
                        </IDClave>
                    </Claves>
                </DatosFactura>
                <TipoDesglose>
                    <DesgloseFactura>
                        <Sujeta>
                            <NoExenta>
                                <DetalleNoExenta>
                                    <TipoNoExenta>S1</TipoNoExenta>
                                    <DesgloseIVA>
                                        <DetalleIVA>
                                            <BaseImponible>${_XMLData['Factura']['BaseImponible']}</BaseImponible>
                                            <TipoImpositivo>${_XMLData['Factura']['TipoImpositivo']}</TipoImpositivo>
                                            <CuotaImpuesto>${_XMLData['Factura']['CuotaImpuesto']}</CuotaImpuesto>
                                        </DetalleIVA>
                                    </DesgloseIVA>
                                </DetalleNoExenta>
                            </NoExenta>
                        </Sujeta>
                    </DesgloseFactura>
                </TipoDesglose>
            </Factura>
        `;

        /* huella */

        _xml += `
            <HuellaTBAI>
        `;

        let _anterior = _XMLData['HuellaTBAI']['anterior'];
        if (_anterior){
            _xml += `
                <EncadenamientoFacturaAnterior>
                    <SerieFacturaAnterior>${_anterior['SerieFacturaAnterior']}</SerieFacturaAnterior>
                    <NumFacturaAnterior>${_anterior['NumFacturaAnterior']}</NumFacturaAnterior>
                    <FechaExpedicionFacturaAnterior>${_anterior['FechaExpedicionFacturaAnterior']}</FechaExpedicionFacturaAnterior>		
                    <SignatureValueFirmaFacturaAnterior>${_anterior['SignatureValueFirmaFacturaAnterior']}</SignatureValueFirmaFacturaAnterior>
                </EncadenamientoFacturaAnterior>
            `;
        }

        _xml += `
            <Software>
                <LicenciaTBAI>${_XMLData['HuellaTBAI']['LicenciaTBAI']}</LicenciaTBAI>
                <EntidadDesarrolladora>
                    <NIF>${_XMLData['HuellaTBAI']['EntidadDesarrolladora']['NIF']}</NIF>
                </EntidadDesarrolladora>
                <Nombre>${_XMLData['HuellaTBAI']['NombreAplicacion']}</Nombre>
                <Version>${_XMLData['HuellaTBAI']['VersionAplicacion']}</Version>
            </Software>
            <NumSerieDispositivo>${_XMLData['HuellaTBAI']['NumSerieDispositivo']}</NumSerieDispositivo>
        `;
        
        _xml += `
            </HuellaTBAI>
        `;

        _xml += `
            </T:TicketBai>   
        `;

        return _xml;
    }

    private async _UnsignedXML(){
        let _XMLData = await this.XMLData();
        if (!_XMLData){
            return null;
        }

        let _reason = (this._change) ? this._change.reason : 'A';
        if (_reason == 'C'){
            return this._UnsignedXML_Baja(_XMLData);
        }
        else {
            return this._UnsignedXML_Alta(_XMLData);
        }    
    }

    private module_forge = null;        // on-demand module loading (saves bundle space)
    private module_xmldsigjs = null;    // on-demand module loading (saves bundle space)
    private module_xadesjs = null;      // on-demand module loading (saves bundle space)
    private module_x509 = null;         // on-demand module loading (saves bundle space)

    private async LoadCryptoModules(){
        if (this.module_forge == null){
            this.module_forge = await import('node-forge');
        }

        if (this.module_xmldsigjs == null){
            this.module_xmldsigjs = await import('xmldsigjs');
        }

        if (this.module_xadesjs == null){
            this.module_xadesjs = await import('xadesjs');
        }

        if (this.module_x509 == null){
            this.module_x509 = await import('@peculiar/x509');
        }
    }

    private _pemcert = null;
    private async CertificateToPEM(certfile){
        if (this._pemcert == null){
            let _pem = null;    // the source PEM certificate
            let _pfx = null;    // the source PFX certificate
    
            await this.LoadCryptoModules();
            let forge = this.module_forge.default || this.module_forge;
            
            if (this._pemcert == null){
                try {   // try with PEM
                    let _certfile = new TextDecoder().decode(certfile);

                    let _pems = forge.pem.decode(_certfile);
                    for (let _pem of _pems) {
                        if ((this._pemcert == null) && _pem.type.includes('CERTIFICATE')) {
                            this._pemcert = _certfile;
                        }
                    }
                } 
                catch (e) {
                    // invalid PEM file, or not a PEM file at all
                }                    
            }

            if (this._pemcert == null){
                try {   // try with PFX
                    const asn1 = forge.asn1.fromDer(forge.util.createBuffer(new Uint8Array(certfile)));
                    _pfx = forge.pkcs12.pkcs12FromAsn1(asn1, await this.ReadPassCert());
                    if (_pfx) {
                        let _certpems = [];

                        // extract the incuded certificates
                        let certBags = _pfx.getBags({bagType: forge.pki.oids.certBag});
                        for (let oid in certBags) {
                            for (let bag of certBags[oid]) {
                                _certpems.push(forge.pki.certificateToPem(bag.cert));
                            }
                        }

                        // extract the private keys
                        let keyBags = _pfx.getBags({bagType: forge.pki.oids.pkcs8ShroudedKeyBag});
                        for (let oid in keyBags) {
                            for (let bag of keyBags[oid]) {
                                _certpems.push(forge.pki.privateKeyToPem(bag.key));
                            }
                        }                            
            
                        this._pemcert = _certpems.join('\n');
                    }
                } 
                catch (e) {
                    // invalid PKCS#12 file, or not a PKCS#12 file at all
                }
            }
        }

        return this._pemcert; 
    }

    private async _SignXades(xmlString, _pemcontent) {
        await this.LoadCryptoModules();

        let forge = this.module_forge.default || this.module_forge;
        let { KeyInfoX509Data, X509Certificate } = this.module_xmldsigjs.default || this.module_xmldsigjs;
        let { Parse, SignedXml } = this.module_xadesjs.default || this.module_xadesjs;
        let x509 = this.module_x509.default || this.module_x509;
        let XAdES = this.module_xadesjs.default || this.module_xadesjs;

        // parse the certificate
        let _certificateRegex = /-----BEGIN CERTIFICATE-----([\s\S]*?)-----END CERTIFICATE-----/g;
        let _certificateMatch = _pemcontent.match(_certificateRegex);
        if (!_certificateMatch) {
            throw new Error('Invalid PEM content: could not find certificate');
        }

        let _certificatePem = _certificateMatch[0];
        let _certificate = forge.pki.certificateFromPem(_certificatePem);

        let _privateKey = null;

        // parse the private key
        if (_privateKey == null){
            let _privateKeyRegex = /-----BEGIN PRIVATE KEY-----([\s\S]*?)-----END PRIVATE KEY-----/g;
            let _privateKeyMatch = _pemcontent.match(_privateKeyRegex);
            if (_privateKeyMatch) {
                let _privateKeyPem = _privateKeyMatch[0];
                _privateKey = forge.pki.privateKeyFromPem(_privateKeyPem);                        
            }
        }

        // parse the RSA private key
        if (_privateKey == null){
            let _rsaPrivateKeyRegex = /-----BEGIN RSA PRIVATE KEY-----([\s\S]*?)-----END RSA PRIVATE KEY-----/g;
            let _rsaPrivateKeyMatch = _pemcontent.match(_rsaPrivateKeyRegex);
            if (_rsaPrivateKeyMatch) {
                let _rsaPrivateKeyPem = _rsaPrivateKeyMatch[0];
                _privateKey = forge.pki.privateKeyFromPem(_rsaPrivateKeyPem);
            }
        }

        if (_privateKey == null){
            throw new Error('Invalid PEM content: could not find private key');
        }

        // converts a forge 0.6.x string of bytes to an ArrayBuffer (https://github.com/digitalbazaar/forge/issues/255)
        function str2ab(str) {
            var b = new ArrayBuffer(str.length);
            var view = new Uint8Array(b);
            for(var i = 0; i < str.length; ++i) {
                view[i] = str.charCodeAt(i);
            }
            return b;
        }

        // obtain the private key to use with xades
        let _privateAsn = forge.pki.privateKeyToAsn1(_privateKey);
        let _privateNfo = forge.pki.wrapRsaPrivateKey(_privateAsn);
        let _privatePk8 = forge.asn1.toDer(_privateNfo).getBytes();   

        let _xadesPrivateKey = await window.crypto.subtle.importKey(
            'pkcs8',
            str2ab(_privatePk8),
            {
                name: 'RSASSA-PKCS1-v1_5',
                hash: { name: 'SHA-256' }
            },
            false,
            ['sign']
        );
          
        // obtain the public key to use with xades
        let _publicKey = _certificate.publicKey;
        let _publicDer = forge.asn1.toDer(forge.pki.publicKeyToAsn1(_publicKey)).getBytes();

        let _xadesPublicKey = await window.crypto.subtle.importKey(
            'spki',
            str2ab(_publicDer),
            {
                name: 'RSASSA-PKCS1-v1_5',
                hash: { name: 'SHA-256' }
            },
            true,
            ['verify']
        );      

        // signing operation starts here

        let _algorithm = {
            name: "RSASSA-PKCS1-v1_5",
            hash: { name: "SHA-256" }
        };  

        let _pemCert = forge.pki.certificateToPem(_certificate);
        let _b64Cert = forge.util.encode64(forge.pem.decode(_pemCert)[0].body);

        let _policy = false;

        let _province = this.PlaceProvince;
        if (_province){
            _policy = this._TicketBai_Url[_province].policy;
        }

        if (_policy){
            _policy['hash'] = 'SHA-256';
        }

        xmlString = xmlString.replace(/\n+/g, '');  // remove all newline characters

        let xmldoc = Parse(xmlString);  
        let _x509data = new KeyInfoX509Data();
        let signedXml = new SignedXml();

        let _reference_id = "Reference-" + GenericUtils.uuidv4();

        // adding of public chain into x509Data block of KeyInfo
        let _x509cert = new x509.X509Certificate(_b64Cert);
        _x509data.AddCertificate(new X509Certificate(_x509cert.rawData));
        signedXml.XmlSignature.KeyInfo.Id = signedXml.XmlSignature.Id + "-KeyInfo";
        signedXml.XmlSignature.KeyInfo.Add(_x509data);

        function _DataObjectFormats() {
            let _dataobject = new XAdES.xml.DataObjectFormat();
            
            _dataobject.ObjectReference = _reference_id;
            _dataobject.ObjectIdentifier = new XAdES.xml.ObjectIdentifier();
            _dataobject.ObjectIdentifier.Identifier = new XAdES.xml.Identifier();
            _dataobject.ObjectIdentifier.Identifier.Qualifier = 'OIDAsURN';
            _dataobject.ObjectIdentifier.Identifier.Value = 'urn:oid:1.2.840.10003.5.109.10';
            _dataobject.MimeType = 'text/xml';

            let _collection = new XAdES.xml.DataObjectFormatCollection();
            _collection.Add(_dataobject);
            return _collection;
        }

        signedXml.Properties.Id = "Signature-" + GenericUtils.uuidv4();
        signedXml.SignedProperties.Id = signedXml.Properties.Id + "-SignedProperties";
        signedXml.SignedProperties.SignedDataObjectProperties.DataObjectFormats = _DataObjectFormats();

        let xmlSigned = await signedXml.Sign(
            _algorithm,
            _xadesPrivateKey,
            xmldoc,
            {
                keyValue: _xadesPublicKey,
                references: [{ 
                    id: _reference_id,
                    hash: "SHA-512", 
                    type: "http://www.w3.org/2000/09/xmldsig#Object",
                    uri: "",
                    transforms: [ 
                        "c14n", 
                        "enveloped", 
                        {
                            name: "xpath",
                            selector: "not(ancestor-or-self::ds:Signature)",
                            namespaces: {
                                ds: "http://www.w3.org/2000/09/xmldsig#"
                            }
                        } 
                    ]                    
                }],
                policy: _policy,
                signingCertificate: _b64Cert
            }
        );

        if(xmlSigned){
            let _xmldoc = new DOMParser().parseFromString(xmlString, "application/xml")
            let _xmlsgn = new DOMParser().parseFromString(xmlSigned.toString(), "application/xml");
            _xmldoc.documentElement.appendChild(_xmldoc.importNode(_xmlsgn.documentElement, true));
    
            return (new XMLSerializer()).serializeToString(_xmldoc);
        } 

        return null;
    }

    private async _buildXML(){        
        let _certfile = await this.ReadUserCert();
        if (_certfile){
            let _pemcert = await this.CertificateToPEM(_certfile);
            if (_pemcert){
                return await this._SignXades(await this._UnsignedXML(), _pemcert);
            }
            else {
                console.error("[TICKETBAI] Unrecognized certificate type (valid types are PEM, DER, PKCS#12")
            }
        }
        else {
            console.error("[TICKETBAI] No certificate has been provided or it cannot be loaded")
        }

        return null;    // error
    }
}
